/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { MuiThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "../../themes/fonts/font.scss";
import { theme } from "../../themes/theme";
import "./layout.scss";
import { initGA } from "../../shared/ga-utils";
import SEO from "../../templates/seo";

import NavbarLazy from "../../templates/Navbar";
import FooterLazy from "../../templates/Footer";
//cookie banner
import CookieConsentLazy from "react-cookie-consent";
import ModalComponent from "../Modal";
import FormSection from "../FormSection";
import { ContactModalProvider } from "../Modal/contactModalContext";

const Layout = ({ children, seo, isLandingPage = false, pageUrl = "" }) => {
  const [delayRender, setDelayRender] = useState(true);

  const isSSR = typeof window === "undefined";

  useEffect(() => {
    // Hide loader after 2 seconds
    const timeout = setTimeout(() => {
      setDelayRender(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (typeof window !== `undefined`) {
    window.onscroll = function () {
      myFunction();
    };
  }

  function myFunction() {
    if (typeof window.orientation == "undefined") {
      let stickyCondition =
        document.getElementsByClassName("sub-title-height-clicked").length > 0
          ? 300
          : 360;
      toggleSticky(stickyCondition);
    }
    if (typeof window.orientation == "number") {
      let stickyCondition =
        document.getElementsByClassName("sub-title-height-clicked").length > 0
          ? 80
          : 80;
      // let stickyCondition = document.getElementsByClassName('sub-title-height-clicked').length > 0 ? 250 : 250;
      toggleSticky(stickyCondition);
    }

    function toggleSticky(stickyCondition) {
      let stickyClass =
        document.getElementsByClassName("sub-title-height-clicked").length > 0
          ? "sticky-clicked"
          : "sticky";

      if (
        document.body.scrollTop > stickyCondition ||
        document.documentElement.scrollTop > stickyCondition
      ) {
        document.getElementById("ImgSubTitle") &&
          document.getElementById("ImgSubTitle").classList.add(stickyClass);
      } else {
        document.getElementById("ImgSubTitle") &&
          document.getElementById("ImgSubTitle").classList.remove(stickyClass);
      }
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("header") &&
          document.getElementById("header").classList.add("toolbar_onscroll");
        document.getElementById("main-container") &&
          document.getElementById("main-container").classList.add("mt-100");
      } else {
        document.getElementById("header") &&
          document
            .getElementById("header")
            .classList.remove("toolbar_onscroll");
        document.getElementById("main-container") &&
          document.getElementById("main-container").classList.remove("mt-100");
      }
    }
  }
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
  return (
    <MuiThemeProvider theme={theme}>
      <SEO
        title={seo?.title}
        ogtitle={seo?.ogtitle}
        description={seo?.description}
        ogimage={seo?.ogimage}
        ogurl={seo?.ogurl}
        keywords={seo?.keywords}
      />
      {!isSSR && (
        <ContactModalProvider>
          <NavbarLazy isLandingPage={isLandingPage} />
          <div style={{ backgroundColor: "white" }}>
            <main id="main-container">{children}</main>
            {!isLandingPage && (
              <footer>
                <FooterLazy />
              </footer>
            )}
            {!delayRender && (
              <CookieConsentLazy
                containerClasses="cookie-banner-style"
                onAccept={handleAcceptCookie}
                hideOnAccept
                buttonText="ACCEPT"
                acceptOnScrollPercentage={80}
                cookieName="gatsby-gdpr-google-analytics"
              >
                We use cookies on our website to improve the quality of user
                experience. By continuing to use this site, you agree to our
                <span>
                  {" "}
                  <a
                    className="cookie-tagclass"
                    target="_blank"
                    href="/cookie-policy"
                  >
                    cookie policy.
                  </a>
                </span>
              </CookieConsentLazy>
            )}
          </div>
          <ModalComponent>
            <FormSection showCloseButton pageUrl={pageUrl} />
          </ModalComponent>
        </ContactModalProvider>
      )}
    </MuiThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
